<template>
    <div class="container_file">
        <embed :src="file" type="application/pdf" class="file">
    </div>
</template>

<script>

export default {
  data: () => ({
    file: '',
    fileD: JSON.parse(localStorage.getItem('fileDataView')),
    userId: '',
  }),
  mounted() {
    this.userId = this.fileD.id
    this.getDataUser()
  },
  methods: {
    async getDataUser() {
      const dataResult = await this.$http
        .get(
          `/api/services/app/UserBrokerProfiles/GetUserBrokerProfileForValidation?Id=${this.userId}`,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )
        .then(response => response)
        .catch(error => {
          this.errors = error.message
        })

      const nameKey = this.fileD.fileKey
      let result = {}
      switch (nameKey) {
        case 'creciFile':
          result = dataResult.data.result.creciFile
          break
        case 'documentFile':
          result = dataResult.data.result.documentFile
          break
        case 'facePhotoCreciFile':
          result = dataResult.data.result.facePhotoCreciFile
          break
        default:
          // facePhotoDocumentFile
          result = dataResult.data.result.facePhotoDocumentFile
      }
      const fileResult = `${result.dataType},${result.bytes}`

      this.file = fileResult
    },
  },
}
</script>
<style scoped>
.container_file {
    position: fixed;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}
.file {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
